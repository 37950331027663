import React, { useMemo } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './TransactionItem.scss';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const b = block('transaction-item');

const TransactionItem = ({ item }) => {
  const date = useMemo(
    () =>
      dayjs(item.date, 'YYYY.MM.DD HH:mm')
        .add(1 * 60, 'minute')
        .format('YYYY.MM.DD HH:mm'),
    [item],
  );

  return (
    <div className={b()}>
      <span className={b('column', { type: 'left' })}>{item.email}</span>
      <span className={b('column', { type: 'center' })}>{date}</span>
      <span className={b('column-amount', { type: 'right' }, { colored: item.amount > 0 })}>{item.amount}</span>
      <span className={b('column', { type: 'right' })}>{item.balance}</span>
    </div>
  );
};

export default TransactionItem;
