import { toFixedHard } from 'shared/utils/toFixedHard';

export class TotalConverter {
  totalConverter = data => ({
    info: {
      credits: toFixedHard(data.credits_sum),
      debits: toFixedHard(data.debits_sum),
      total: toFixedHard(data.total),
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
    })),
  });

  fullTotalConverter = data => ({
    info: {
      credits: toFixedHard(data.credits_sum),
      debits: toFixedHard(data.debits_sum),
      total: toFixedHard(data.total),
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
      ...this.convertUser(t),
    })),
  });

  convertUser = data => ({
    credits: toFixedHard(data.credits_sum),
    debits: toFixedHard(data.debits_sum),
    total: toFixedHard(data.total),
  });
}
