import { toFixedHard } from "shared/utils/toFixedHard";

export class UserApiConverter {
  convertUsersList = data => ({
    users: (data?.users || []).map(user => this.convertUser(user)),
    quantity: data?.quantity || 0,
    totalBalance: toFixedHard(data?.balance_sum || 0),
  });

  convertUser = user => ({
    id: user.id,
    nickname: user.nickname,
    balance: toFixedHard(user.balance),
  })

  convertNewPassword = data => data.new_password;
}
