import React, { useEffect, useState } from 'react';
// import block from 'bem-cn';
import PT from 'prop-types';
import createActivityDetector from 'activity-detector';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// import Button from 'components/Button/desktop';
// import Modal from 'components/Modal/desktop';

import { actions as authActions } from 'features/Auth';

import './Inactive.scss';

// const b = block('inactive');

const activityDetector = createActivityDetector({
  timeToIdle: 900000,
  autoInit: false,
  inactivityEvents: [],
});

const Inactive = ({ logout, isAuth, locale }) => {
  const [, /* isModalOpen */ setIsModalOpen] = useState(false);
  activityDetector.on('idle', () => {
    logout();
    setIsModalOpen(true);
  });

  useEffect(() => {
    if (isAuth) {
      activityDetector.init();
    } else {
      activityDetector.stop();
    }
  }, [isAuth]);

  // const closeFunction = () => setIsModalOpen(false);

  return (
    // isModalOpen ? (
    //   <Modal closeFunction={closeFunction}>
    //     <div className={b()}>
    //       {locale.autoLogoutText}
    //       <div className={b('button-container')}>
    //         <Button text="OK" color="red" size="low" callBack={closeFunction} />
    //       </div>
    //     </div>
    //   </Modal>
    null
  );
};

Inactive.propTypes = {
  isAuth: PT.bool.isRequired,
  locale: PT.object.isRequired,
  logout: PT.func.isRequired,
};

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  locale: state.locale.common,
});

const mapDispatchToProps = dispatch => {
  const actions = {
    logout: authActions.logOut,
  };
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Inactive);
