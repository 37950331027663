import BaseApi from '../BaseApi';
import { TreeApiConverter } from './TreeApiConverter';

class TreeApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new TreeApiConverter();
  }

  changeUserStateByAction = ({ id, value, name }) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/balance_tree`, {
      action: name,
      state: value,
      target_user: id,
    });

  getUsersByUserId = ({ id }) =>
    this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/balance_tree?target_user=${id}`, null, null, data =>
      this.converter.convertUsersById({ data, id }),
    );
}

export default TreeApi;
