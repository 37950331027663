import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import openSvg from './img/open.svg';
import awaySvg from './img/away.svg';

import './MenuItem.scss';

const MenuItem = ({ locale, item, role = 1 }) => {
  const b = block('menu-item');
  const [isOpen, changeOpen] = useState(false);
  const itemList = useMemo(
    () =>
      item.items.map(temp => (
        <Link className={b('element')} key={temp.textId} to={temp.route}>
          {locale[temp.textId]?.[role]}
          <SVGInline svg={awaySvg} className={b('image', { type: 'away' }).toString()} />
        </Link>
      )),
    [b, item.items, locale, role],
  );
  return (
    <React.Fragment>
      <div className={b()} onClick={() => changeOpen(!isOpen)}>
        {locale[item.textId]}
        <SVGInline svg={openSvg} className={b('image', { isOpen }).toString()} />
      </div>
      {isOpen && <div className={b('element-list')}>{itemList}</div>}
    </React.Fragment>
  );
};

export default MenuItem;
