import { toFixedHard } from "shared/utils/toFixedHard";

export class TransactionApiConverter {
  convertTransfers = ({ total_deposit, total_withdrawal, data = [] }) => {
    return {
      totalDeposit: toFixedHard(total_deposit),
      totalWithdrawal: toFixedHard(total_withdrawal),
      data: data.map(user => this.convertUser(user)),
    };
  };

  convertUser = user => ({
    email: user.email,
    date: user.date,
    amount: toFixedHard(user.amount),
    balance: toFixedHard(user.balance),
  });
}
