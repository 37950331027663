import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import { actions } from 'features/users/redux';

import Input from 'components/Input';
import Button from 'components/Button';

import searchSvg from '../img/search.svg';
import arrowSvg from '../img/arrow.svg';

import './UsersList.scss';

const b = block('users-list');

const UsersList = () => {
  const [filterValue, onChangeFilterValue] = useState('');
  const [trigger, setTrigger] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUsersList());
  }, [dispatch]);

  const clickSearchButton = () => {
    setTrigger({});
  };

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
    if (e.currentTarget.value === '') clickSearchButton();
  }, []);

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);

  const list = useMemo(
    () =>
      usersList.users
        ?.filter(t => ~t.nickname?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .map(item => (
          <Link key={item.id} className={b('item')} to={`/user-edit/${item.id}`}>
            <div className={b('item-nick')}>{`${item.nickname} (ID: ${item.id})`}</div>
            <div className={b('item-right')}>
              <div className={b('item-balance')}>{item.balance}</div>
              <div className={b('arrow-box')}>
                <SVGInline svg={arrowSvg} className={b('arrow').toString()} />
              </div>
            </div>
          </Link>
        )) || [],
    [usersList, trigger],
  );

  return (
    <div className={b()}>
      <div className={b('filter-block')}>
        <div className={b('header')}>{locale.userCardBalances}:</div>
        <div className={b('row')}>
          <div className={b('input-wrapper')}>
            <SVGInline svg={searchSvg} className={b('search-icon').toString()} />
            <Input
              value={filterValue}
              callBack={changeFilterValue}
              style={{ paddingLeft: '2.8rem' }}
              placeholder={`${locale.search}...`}
            />
          </div>
          <div className={b('button')}>
            <Button text={locale.search} callBack={clickSearchButton} upperCased />
          </div>
        </div>
      </div>
      <div className={b('subheader')}>
        <div className={b('user-card')}>
          <div className={b('item-nick')}>{locale.userCard}</div>
          <div className={b('item-right')}>
            <span className={b('bold-text')}>{usersList.quantity}</span>
          </div>
        </div>
        <div className={b('account-balance')}>
          <div className={b('item-nick')}>{locale.accountBalance}</div>
          <div className={b('item-right')}>
            <span className={b('bold-text')}>{usersList.totalBalance}</span>
          </div>
        </div>
      </div>
      <div className={b('list')}>{list}</div>
    </div>
  );
};

export default UsersList;
