import dayjs from 'dayjs';
import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'total/ACTION_PROCESSING',
  GET_TOTAL_SUCCESS: 'total/GET_TOTAL_SUCCESS',
  GET_USER_SUCCEEDED: 'total/GET_USER_SUCCEEDED',
};

const getTotal = (fromDate, toDate) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { role } = getState().auth;
  let to = toDate;
  to = dayjs(toDate)
    .add(+1, 'day')
    .format('YYYY-MM-DD');
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = role === 1 ? await api.total.getTotal(fromDate, to) : await api.total.getFullTotal(fromDate, to);
  if (response.success) {
    dispatch({ type: actionTypes.GET_TOTAL_SUCCESS, payload: response.data });
  } else {
    dispatch(addNotify('Error', 'error'));
  }
};

const getUserData = (id, fromDate, toDate) => async (dispatch, getState, extra) => {
  const { api } = extra;
  let to = toDate;
  to = dayjs(toDate)
    .add(+1, 'day')
    .format('YYYY-MM-DD');
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.total.getUserData(id, fromDate, to);
  if (response.success) {
    dispatch({ type: actionTypes.GET_USER_SUCCEEDED, payload: { data: response.data, id } });
  } else {
    dispatch(addNotify('Error', 'error'));
  }
};

export { actionTypes, getTotal, getUserData };
